/* eslint-disable react/jsx-props-no-spreading */
import React, { ErrorInfo } from 'react';
import App, { AppInitialProps } from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import ReactGA from 'react-ga';
import { ReactQueryDevtools } from 'react-query-devtools';
import '../components/global-styles.css';
import i18nConfig from '../i18n.config';
import {
  changeDocumentLanguage, setI18nCookie,
} from '../utils/i18n';
import { JsonMap } from '../types/json';

import { GA_TRACKING_ID, GA_CONFIGS } from '../configs/ga';

const { allLanguages, defaultLanguage } = i18nConfig;

const TranslationsNeeded = 'pagesTranslations/_app';

const initGA = (): void => {
  if (
    typeof window !== 'undefined'
    // @ts-expect-error
    && !window.isGAInitialized
  ) {
    ReactGA.initialize(GA_TRACKING_ID, GA_CONFIGS);
    // @ts-expect-error
    window.isGAInitialized = true;
  }
};

const onPageView = (): void => {
  if (typeof window !== 'undefined') {
    const location = window.location.pathname + window.location.search;
    ReactGA.set({ page: location });
    ReactGA.pageview(location);
  }
};

interface Metric {
  id: string;
  name: string;
  startTime: number;
  value: number;
  label: 'web-vital' | 'custom';
}

// Next.JS handles this
// that's why it's not called here
// https://nextjs.org/docs/advanced-features/measuring-performance#web-vitals
export function reportWebVitals({
  id, name, startTime, value, label,
}: Metric): void {
  ReactGA.event({
    category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
    action: name,
    value: Math.round(name === 'CLS' ? value * 1000 : value),
    label: id,
    nonInteraction: true,
  });
}

interface I18nProps {
  translations: JsonMap
}

class MyApp extends App<AppInitialProps & I18nProps> {
  componentDidMount(): void {
    const {
      pageProps,
    } = this.props;

    initGA();

    const { language } = pageProps;
    const languageObject = allLanguages[language as string];
    if (languageObject) {
      // 1. Change the language again in case it was a client-side
      // transition (_document.tsx only runs on the server)
      changeDocumentLanguage(languageObject.prefix);

      // 2. Update the "preferred-language" cookie
      setI18nCookie(languageObject.prefix);

      // 3. Set dir
      const direction = languageObject.direction || 'ltr';
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      window.document.querySelector('html')!.dir = direction;
    }

    Router.events.on('routeChangeComplete', onPageView);
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    initGA();
    ReactGA.exception({
      stack: error.stack,
      componentStack: errorInfo.componentStack,
      isDevEnv: process.env.NODE_ENV === 'development',
    });
    super.componentDidCatch(error, errorInfo);
  }

  public render(): React.ReactElement {
    const {
      Component, pageProps,
    } = this.props;

    const translations = pageProps.translations[TranslationsNeeded];

    const { language } = pageProps;
    const languageObject = allLanguages[language as string] || defaultLanguage;
    const direction = languageObject.direction || 'ltr';

    // 3. Set the dir div
    return (
      <>
        <Head>
          {/* Cool web standards */}
          <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />

          <title>{translations.title}</title>
          <meta name="title" content={translations.title} key="title" />
          <meta name="description" content={translations.description} key="description" />

          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="theme-color" content="#f8f8f8" />
          <link rel="icon" type="image/png" sizes="16x16" href="/logo/16w/logo_light_bg.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/logo/32w/logo_light_bg.png" />
          <link rel="manifest" href={`/${translations.manifest}.json`} />

          {/* Apple stuff.. yuk */}
          {/* For some reason, this is being fetched as HTTPS but in HTTP on Firefox */}
          <link rel="apple-touch-icon" sizes="180x180" href="/logo/180w/logo_light_bg.png" />
          <meta name="apple-mobile-web-app-status-bar-style" content="#f8f8f8" />
          <meta name="apple-mobile-webapp-title" content={translations.title} />
          <meta name="apple-touch-fullscreen" content="yes" />
          <meta name="mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="application-name" content={translations.title} />

          {/* MS stuff.. yuk */}
          <meta name="msapplication-TileColor" content="#f6f8fa" />

          {/* SEO */}
          <meta property="og:type" content="website" key="og:type" />
          {/*
          <meta property="og:url" content={`https://mckany.com/${language}`} />
          */}
          <meta property="og:title" content={translations.title as string} key="og:title" />
          <meta property="og:description" content={translations.description as string} key="og:description" />
          <meta property="og:image" content={`https://mckany.com/logo/256w/logo_text_${language}.png`} key="og:image" />
          <meta property="og:locale" content={language} />
          <meta property="og:locale:alternate" content={language === 'ar' ? 'en' : 'ar'} />
          <meta property="og:site_name" content="Mckany" />

          <meta property="twitter:card" content="summary_large_image" key="twitter:card" />
          {/*
          <meta property="twitter:url" content={`https://mckany.com/${language}`} />
          */}
          <meta property="twitter:title" content={translations.title as string} key="titter:title" />
          <meta property="twitter:description" content={translations.description as string} key="twitter:description" />
          <meta property="twitter:image" content={`https://mckany.com/logo/256w/logo_text_${language}.png`} key="twitter:image" />
        </Head>
        <ReactQueryDevtools initialIsOpen={false} />
        {/* Setting dir here as well as in componentDidMount to avoid having the app
        prerendered in the wrong direction or having it render the first render in
        the wrong direction in general */}
        <div
          dir={direction}
        >
          <Component
            {...pageProps}
          />
        </div>
      </>
    );
  }
}

export default MyApp;
